export default {
  pageHeroV1: {
    container: {
      height: '50vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '3.5rem'],
        letterSpacing: '3px',
        textTransform: 'uppercase',
        color: 'white',
        backgroundColor: 'rgb(255 255 233 / 43%)',
        padding: '1rem 1rem 0.8rem',
        borderBottom: '4px solid',
        borderColor: 'secondary',
        color: 'primary',
        marginBottom: '2rem'
      },

      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
